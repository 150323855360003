import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../../Constants';

@Injectable()
export class HouseownerPropertyDetailsService {

    constructor(private http: HttpClient) {
    }

    listProperties(type?: string) {
        if (type) {
            return this.http.get(Constants.BASE_API_URL + '/house-owner/property/?type=' + (type ? type : ''));
        } else {
            return this.http.get(Constants.BASE_API_URL + '/house-owner/property/');
        }
    }

    addProperties(data) {
        return this.http.post(Constants.BASE_API_URL + '/house-owner/property/add', data);
    }

    editProperties(data, id) {
        return this.http.post(Constants.BASE_API_URL + '/house-owner/property/edit/' + id, data);
    }

    viewProperties(id) {
        return this.http.get(Constants.BASE_API_URL + '/house-owner/property/view/' + id);
    }

    deleteProperties(id) {
        return this.http.get(Constants.BASE_API_URL + '/house-owner/property/delete/' + id);
    }

    deleteAll(data) {
        return this.http.post(Constants.BASE_API_URL + '/house-owner/property/delete/', data);
    }

    listPoliceUser() {
        return this.http.get(Constants.BASE_API_URL + '/house-owner/property/police');
    }

    uploadPropertyPhoto(id, data) {
        return this.http.post(Constants.BASE_API_URL + '/house-owner/property/property-photo/upload/' + id, data);
    }

    ViewAllAvailableRentReceipt(id) {
        return this.http.post(Constants.BASE_API_URL + '/house-owner/tenant/available-rent-receipt/' + id, {});
    }

    AddRentReceipt(data) {
        return this.http.post(Constants.BASE_API_URL + '/house-owner/tenant/add-rental', data);
    }

}