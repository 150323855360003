import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../../Constants';

@Injectable()
export class AdminCategoryService {

    constructor(private http: HttpClient) {}

    listCategory() {
        return this.http.get(Constants.BASE_API_URL + '/admin/category/');
    }

    addCategory(data) {
        return this.http.post(Constants.BASE_API_URL + '/admin/category/add', data);
    }

    editCategory(id, data) {
        return this.http.post(Constants.BASE_API_URL + '/admin/category/edit/' + id, data);
    }

    viewCategory(id) {
        return this.http.get(Constants.BASE_API_URL + '/admin/category/view/' + id);
    }

    deleteCategory(id) {
        return this.http.get(Constants.BASE_API_URL + '/admin/category/delete/' + id);
    }
}