import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../../Constants';

@Injectable()
export class AdminUsersService {

    constructor(private http: HttpClient) {
    }

    addPoliceUser(data) {
        return this.http.post(Constants.BASE_API_URL + '/admin/users/police/add', data);
    }

    editPoliceUser(data, id) {
        return this.http.post(Constants.BASE_API_URL + '/admin/users/police/edit/' + id, data);
    }

    viewPoliceUser(id) {
        return this.http.get(Constants.BASE_API_URL + '/admin/users/police/view/' + id);
    }

    deletePoliceUser(id) {
        return this.http.get(Constants.BASE_API_URL + '/admin/users/police/delete/' + id);
    }

    listPoliceUser() {
        return this.http.get(Constants.BASE_API_URL + '/admin/users/police/');
    }

    listUsers(type) {
        return this.http.get(Constants.BASE_API_URL + '/admin/users/' + type);
    }

    listAllUsers() {
        return this.http.get(Constants.BASE_API_URL + '/admin/users/');
    }

    addUser(data) {
        return this.http.post(Constants.BASE_API_URL + '/admin/users/add', data);
    }

    editUser(data, id) {
        return this.http.post(Constants.BASE_API_URL + '/admin/users/edit/' + id, data);
    }

    viewUser(id) {
        return this.http.get(Constants.BASE_API_URL + '/admin/users/view/' + id);
    }

    deleteUser(id) {
        return this.http.get(Constants.BASE_API_URL + '/admin/users/delete/' + id);
    }

    deleteAllUser(data) {
        return this.http.post(Constants.BASE_API_URL + '/admin/users/deleteall',data);
    }

    deleteAllPoliceUsers(data) {
        return this.http.post(Constants.BASE_API_URL + '/admin/users/police/deleteall',data);
    }

    enableDisableUser(id) {
        return this.http.get(Constants.BASE_API_URL + '/admin/users/action/' + id);
    }

    enableDisablePolice(id) {
        return this.http.get(Constants.BASE_API_URL + '/admin/users/police/action/' + id);
    }

    usersResetPassword(data) {
        return this.http.post(Constants.BASE_API_URL + '/admin/users/change-password', data);
    }

    policeResetPassword(data) {
        return this.http.post(Constants.BASE_API_URL + '/admin/users/police/change-password', data);
    }

    SearchUsers(type, data) {
        return this.http.post(Constants.BASE_API_URL + '/admin/users/search/' + type, data);
    }
}